<template>
  <div class="row">
      <div class="col-12">
          <h1 class="fw-700 text-center mt-3 mb-5"><i class="fas fa-chart-bar text-primary mr-2"></i> Attitudes Result</h1>
      </div>
      <div class="col-12">
          <h5 class="">Result Of Your Attitudes By Attendance On Chart</h5>
          <apexchart height="250" width="100%" type="bar" :options="chart.attendance.option" :series="chart.attendance.series"></apexchart>
      </div>
      <div class="col-12">
          <h5 class="">Result Of Your Attitudes By Submission On Chart</h5>
          <apexchart height="250" width="100%" type="bar" :options="chart.submission.option" :series="chart.submission.series"></apexchart>
      </div>
      <div v-if="table" class="col-12">
          <h5 class="mb-3">Total Submission</h5>
          <div class="row justify-content-start mb-3">
              <div v-for="(item,index) in table" :key="index" class="col-1"><button @click="setSelected(item)" class="btn btn-block btn-success">{{index + 1}}</button></div>
          </div>
          <div v-if="selected" id="accordion" class="accordion mb-0">
              <div class="card shadow-xss mb-0">
                  <div class="card-header bg-greylight theme-dark-bg" id="heading">
                      <h5 class="mb-0"><button class="btn font-xsss fw-600 btn-link collapsed" data-toggle="collapse" data-target="#collapse" aria-expanded="false" aria-controls="collapsei"> Table of Submission</button></h5>
                  </div>
                  <div id="collapse" class="collapse p-0" aria-labelledby="heading" data-parent="#accordion">
                      <div class="card-body d-flex p-0">
                          <table class="table">
                              <thead>
                                  <tr>
                                      <th>Assignment</th>
                                      <th>Total</th>
                                      <th>Submissiom</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr v-for="(item, index) in selected" :key="index">
                                      <td>{{item.name}}</td>
                                      <td>{{item.count}} Assignment</td>
                                      <td>{{item.submission}} Submission</td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                  </div>
              </div>
          </div>
      </div> 
  </div>
</template>
<script>
import axios from 'axios'
export default {
  name: `Views`,
  data(){
      return {
          idUser: this.$route.params.idStudent,
          slug: this.$route.params.idCourse,
          selected: null,
          table: null,
          chart: {
              attendance: {
                  option: {
                      chart: {
                          id: 'vuechart-example'
                      },
                      noData: {  
                          text: "Loading...",  
                          align: 'center',  
                          verticalAlign: 'middle',  
                          offsetX: 0,  
                          offsetY: 0,  
                          style: {  
                              color: "#000000",  
                              fontSize: '14px',  
                              fontFamily: "Helvetica"  
                          }  
                      },
                  },
                  series: [],
              },
              submission: {
                  option: {
                      chart: {
                          id: 'vuechart-example'
                      },
                      noData: {  
                          text: "Loading...",  
                          align: 'center',  
                          verticalAlign: 'middle',  
                          offsetX: 0,  
                          offsetY: 0,  
                          style: {  
                              color: "#000000",  
                              fontSize: '14px',  
                              fontFamily: "Helvetica"  
                          }  
                      },
                  },
                  series: [],
              },
          },
      }
  },
  created(){
      this.getAttendance();
      this.getSubmission();
      this.getTableSubmission();
  },
  methods: {
      setSelected(item){
          this.selected = item.table;
      },
      async getAttendance() {
          await axios.get(`${process.env.VUE_APP_URL_API}/admin/evaluation/attitude/view/attendance?slug=${this.slug}&user_id=${this.idUser}`, {
              headers: {
                  Authorization: `Bearer ${localStorage.token}`
              }
          }).then(res => {
              this.chart.attendance = res.data.data
          })
      },
      async getSubmission() {
          await axios.get(`${process.env.VUE_APP_URL_API}/admin/evaluation/attitude/view/submission?slug=${this.slug}&user_id=${this.idUser}`, {
              headers: {
                  Authorization: `Bearer ${localStorage.token}`
              }
          }).then(res => {
              this.chart.submission = res.data.data
          })
      },
      async getTableSubmission() {
          await axios.get(`${process.env.VUE_APP_URL_API}/admin/evaluation/attitude/view/table-submission?slug=${this.slug}&user_id=${this.idUser}`, {
              headers: {
                  Authorization: `Bearer ${localStorage.token}`
              }
          }).then(res => {
              this.table = res.data.data
          })
      },
      
  }
}
</script>