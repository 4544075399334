<template>
  <div class="row">
      <div class="col-12">
          <h1 class="fw-700 text-center mt-3 mb-5"><i class="fas fa-exclamation-circle text-info mr-2"></i> List Your Own Submissions</h1>
          <div v-if="!isLoad" class="table-responsive">
              <table class="table table-hover table-striped">
                  <tbody v-if="selected">
                      <tr class="text-center" v-for="(item, n) in selected" :key="n">
                          <td align="center" valign="middle" class="py-5" width="25%">
                              <h1 class="font-xsss fw-800" :class="colors[n]">{{item.name}}</h1>
                          </td>
                          <td align="center" valign="middle" class="py-5">
                              <h1 class="font-xsss fw-700">{{item.count}} Assignments</h1>
                          </td>
                          <td align="center" valign="middle" class="py-5">
                              <h1 class="font-xsss fw-700">{{item.submission}} Submissions</h1>
                          </td>
                      </tr>
                  </tbody>
                  <tbody v-else>
                      <tr class="text-center">
                          <td colspan="3" align="center" valign="middle" class="py-3">
                              <h3 class="fw-800">Data is empty</h3>
                          </td>
                      </tr>
                  </tbody>
              </table>
          </div>
          <div v-else class="w-100 py-3 text-center">
              <div class="my-3">
                  <div class="spinner-grow text-warning mx-3" role="status">
                      <span class="sr-only">Loading...</span>
                  </div>
                  <div class="spinner-grow text-warning mx-3" role="status">
                      <span class="sr-only">Loading...</span>
                  </div>
                  <div class="spinner-grow text-warning mx-3" role="status">
                      <span class="sr-only">Loading...</span>
                  </div>
                  <div class="spinner-grow text-warning mx-3" role="status">
                      <span class="sr-only">Loading...</span>
                  </div>
                  <div class="spinner-grow text-warning mx-3" role="status">
                      <span class="sr-only">Loading...</span>
                  </div>
                  <div class="spinner-grow text-warning mx-3" role="status">
                      <span class="sr-only">Loading...</span>
                  </div>
              </div>
          </div>
      </div> 
  </div>
</template>
<script>
import axios from 'axios'
export default {
  props: ['cycle'],
  data(){
      return {
          idUser: this.$route.params.idStudent,
          slug: this.$route.params.idCourse,
          isLoad: true,
          outcomes: null,
          selected: null,
          colors: ['text-primary', 'text-current', 'text-success', 'text-danger', 'text-black']
      }
  },
  created(){
      this.getOutcomes()
  },
  methods:{
      async getOutcomes(){
          await axios.get(`${process.env.VUE_APP_URL_API}/admin/evaluation/attitude/outcome?slug=${this.slug}&user_id=${this.idUser}`, {
              headers: {
                  Authorization: `Bearer ${localStorage.token}`
              }
          }).then(res => {
              if (res.data.success) {
                  this.isLoad = false
                  this.outcomes = res.data.data
                  if(this.cycle){
                      this.selected = this.outcomes[this.cycle - 1].table;
                  }
              }
          })
      }
  },
  watch: {
      cycle: function(){
          this.getOutcomes()
      }
  }
}
</script>