<template>
    <div class="row justify-content-center">
        <div class="col-lg-12 mb-5">
            <div class="card w-100 bg-lightblue p-lg-4 pt-lg-5 border-0 rounded-lg d-block float-left">
                <img src="/images/profile.png" alt="icon" class="sm-mt-2 w75 position-relative top--10 float-left mr-2 mt--1 ">
                <h2 class="font-lg d-inline-block float-left mb-0 text-grey-900 fw-700">
                    <span class="font-xssss fw-600 text-grey-500 d-block mb-2 ml-1">{{user.email}}</span>{{user.name}}
                </h2>
            </div>
        </div>
        <div class="col-lg-12">
            <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden mb-4">
                <ul class="nav nav-tabs xs-p-4 d-flex align-items-center justify-content-around product-info-tab border-bottom-0" id="pills-tab" role="tablist">
                    <li class="list-inline-item active"><a class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase active" href="#navtabs0" data-toggle="tab">View</a></li>
                    <li class="list-inline-item"><a class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase" href="#navtabs1" data-toggle="tab">Outcomes</a></li>
                </ul>
            </div>
            <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active" id="navtabs0">
                    <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
                        <Views></Views>
                    </div>
                </div>
                <div class="tab-pane fade" id="navtabs1">
                    <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
                        <Outcomes :cycle="cycle"></Outcomes>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import Views from './Sub/Attitude/Views.vue'
import Outcomes from './Sub/Attitude/Outcomes.vue'
export default {
    name: 'Attitude',
    components:{
        Views,
        Outcomes,
    },
    data(){
        return {
            idUser: this.$route.params.idStudent,
            user: {
                name: '',
                email: '',
            },
            cycle: 1
        }
    },
    created(){
        this.getUser()
    },
    methods: {
        async getUser() {
            await axios.get(`${process.env.VUE_APP_URL_API}/admin/user?id=${this.idUser}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.user = res.data
            }).catch(() => {
                this.$swal({
                toast: true,
                title: 'Report',
                text: 'Failed Load User!',
                icon: 'error',
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                })
            })
        },
    }
}
</script>